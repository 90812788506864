const App = () => {
  return (
    <div className="home">
      <h1>TCH Designer</h1>
      <div className="link-container">
        <a href="/generator">Generator</a>
        <a href="/resizer">Resizer</a>
      </div>
    </div>
  );
};

export default App;
