import "./App.scss";
import { toJpeg, toPng } from "html-to-image";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { HexColorPicker } from "react-colorful";
import gshBlackLogo from "./assets/logos/social-logos_gsh-black.png";
import gshBlueLogo from "./assets/logos/social-logos_gsh-blue.png";
import gshGreenLogo from "./assets/logos/social-logos_gsh-green.png";
import gshRedLogo from "./assets/logos/social-logos_gsh-red.png";
import gshWhiteLogo from "./assets/logos/social-logos_gsh-white.png";
import gshYellowLogo from "./assets/logos/social-logos_gsh-yellow.png";

const colors = {
  beige: "#f6f4ef",
  black: "#000000",
  white: "#ffffff",
  red: "#EB1D23",
  mustard: "#FFDE59",
  yellow: "#FFFF00",
  pink: "#FF87CF",
  green: "#008300",
  grey: "#D4D2CD",
  blue: "#003A68",
};

const months = [
  "Janar",
  "Shkurt",
  "Mars",
  "Prill",
  "Maj",
  "Qershor",
  "Korrik",
  "Gusht",
  "Shtator",
  "Tetor",
  "Nentor",
  "Dhjetor",
];

const logos = {
  "gsh-black": gshBlackLogo,
  "gsh-blue": gshBlueLogo,
  "gsh-green": gshGreenLogo,
  "gsh-red": gshRedLogo,
  "gsh-white": gshWhiteLogo,
  "gsh-yellow": gshYellowLogo,
};

function Generator() {
  const [input, setInput] = useState({
    img: "https://gsh.top-media.al/assets/gazeta-shqip-cover.jpg",
    title:
      "KY ESHTE TITULLI DHE NUK DUHET TE JETE ME SHUME SE 4 RRESHTA I GJATE. KLIKO PER TA MODIFIKUAR",
    sub: "KY ESHTE PERSHKRIMI OSE AUTORI DHE NUK DUHET TE JETE ME I GJATESE TRE RRESHTA.",
    authors: "Nga Gazeta Shqip",
    date: null,
    bg: colors.beige,
    fg: colors.black,
    hr: colors.black,
    page: "gsh-black",
  });

  const [img64, setImg64] = useState();

  const [apiEndpoint, setApiEndpoint] = useState("");
  const [isAuthor, setIsAuthor] = useState(false);
  const [customTemplate, setCustomTemplate] = useState(false);
  const ref = useRef(null);
  const previewRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [error, setError] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (input.date == null) {
      const date = new Date();
      const nowDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
      setInput({...input, date: nowDate});
    }
    const postId = params.get("id");
    const pKey = params.get("pkey");
    const user = params.get("user");
    if (postId && pKey && user)
      setApiEndpoint(
        "https://gshadmin.top-media.al/wp-json/v1/preview/" +
          postId +
          "?pkey=" +
          pKey +
          "&user=" +
          user
      );
  }, []);

  useEffect(() => {
    if (apiEndpoint === "") return;
    axios({
      method: "GET",
      url: apiEndpoint,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) return;
        const data = res.data;
        const authors =
          data.terms.autor !== undefined
            ? data.terms.autor.map((a) => a.name).join(", ")
            : "";
        const date = new Date(data.date);
        setInput({
          ...input,
          img:
            data.feature.image ??
            "https://gsh.top-media.al/assets/gazeta-shqip-cover.jpg",
          title: data.title,
          sub: data.excerpt,
          authors: "Nga " + authors,
          date:
            date.getDate() +
            " " +
            months[date.getMonth()] +
            " " +
            date.getFullYear(),
        });
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.message);
      });
  }, [apiEndpoint]);

  useEffect(() => {
    calculateScale();
    window.onresize = () => {
      calculateScale();
    };
  }, []);

  const calculateScale = () => {
    const windowWidth = window.outerWidth;
    const windowHeight = window.outerHeight;
    console.log("windowW", windowWidth);

    if (windowWidth < 1000) {
      const scaleX = windowWidth / 1080;
      const scaleY = windowHeight / 1920;
      //mantain aspect ratio
      const finalScale = scaleX > scaleY ? scaleY : scaleX;
      setScale(finalScale);
    } else {
      const theScale = windowHeight / 1920;
      console.log("scale", theScale);
      setScale(theScale > 1 ? 1 : theScale);
    }
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    const img64 = getBase64FromUrl(input.img)
      .then((value) => {
        setImg64(value);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [input.img]);

  const colorProfiles = {
    "GSH Beige-Black": {
      bg: colors.beige,
      fg: colors.black,
      hr: colors.black,
      logo: "gsh-black",
    },
    "GSH Beige-Red": {
      bg: colors.beige,
      fg: colors.black,
      hr: colors.red,
      logo: "gsh-red",
    },
    "GSH Black-Red": {
      bg: colors.black,
      fg: colors.white,
      hr: colors.red,
      logo: "gsh-red",
    },
    "GSH Black-White": {
      bg: colors.black,
      fg: colors.white,
      hr: colors.white,
      logo: "gsh-white",
    },
    "GSH Mustard-Yellow": {
      bg: colors.mustard,
      fg: colors.black,
      hr: colors.yellow,
      logo: "gsh-yellow",
    },
    "GSH Pink-Green": {
      bg: colors.pink,
      fg: colors.black,
      hr: colors.green,
      logo: "gsh-green",
    },
    "GSH Grey-Blue": {
      bg: colors.grey,
      fg: colors.black,
      hr: colors.blue,
      logo: "gsh-blue",
    },
  };

  const saveAsJpeg = () => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, {
      canvasHeight: 1920,
      canvasWidth: 1080,
      quality: 0.95,
      pixelRatio: 1,
    })
      .then((dataUrl) => {
        const link = document.createElement("a", {
          is: "external-link",
          target: "_blank",
        });
        link.download = "story.png";
        link.href = dataUrl;
        link.crossOrigin = "anonymous";
        link.click();
      })
      .catch((err) => {
        console.log(err.message);
        setError(err.message);
      });
  };

  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  return (
    <div className="App">
      {error != null && <div className="error">{error}</div>}
      <div className="actual">
        <div className="previewWrapper">
          <div
            className="preview"
            ref={previewRef}
            style={{ transform: `scaleX(${scale}) scaleY(${scale})` }}
          >
            <header
              style={{ backgroundColor: input.bg, color: input.fg }}
              className="App-header"
              ref={ref}
            >
              <div className="top">
                <div className="imgHead">
                  <img src={logos[input.page]} className="logo" alt="logo" />
                  <span
                    className="logoText"
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                  >
                    {input.date}
                  </span>
                </div>
                <div className="text">
                  <p
                    className="title"
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                  >
                    {input.title}
                  </p>
                  <div
                    className="hline"
                    style={{ backgroundColor: input.hr }}
                  ></div>
                  <p
                    className={isAuthor ? "sub author" : "sub"}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                  >
                    {isAuthor === false ? input.sub : input.authors}
                  </p>
                </div>
              </div>
              <div className="bgimg">
                {img64 ? <img src={img64} alt="bg" /> : <>Loading ...</>}
              </div>
            </header>
          </div>
        </div>
        <div className="conrols">
          <div className="controls-inner">
            <div className="row">
              <h2>GSH Instagram Story Maker</h2>
            </div>
            <div className="row">
              <div className="form-group">
                <label>Background Image</label>
                <input
                  value={input.img}
                  name="img"
                  type="text"
                  key={"img"}
                  placeholder="Image Url"
                  onChange={inputHandler}
                />
              </div>
              <div className="form-group">
                <input
                  type="file"
                  key={"file"}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setInput({ ...input, img: reader.result });
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>

            <div className="form-group check">
              <input
                type="checkbox"
                key={"isAuthor"}
                checked={isAuthor}
                onChange={(e) => setIsAuthor(e.target.checked)}
                id="isAuthor"
              />
              <label htmlFor="isAuthor">
                Show author name instead of description
              </label>
            </div>
            <div className="form-group">Background Color</div>
            <div className="form-group">
              <div className="color-picker">
                {Object.keys(colorProfiles).map((key) => (
                  <div className="color-item" key={"el-pickero" + key}>
                    <input
                      type="radio"
                      name="bg"
                      key={"other-one-" + key}
                      id={"other-one-" + key}
                      value={colorProfiles[key].bg}
                      checked={
                        input.bg === colorProfiles[key].bg &&
                        input.page === colorProfiles[key].logo &&
                        !customTemplate
                      }
                      onChange={(e) => {
                        if (customTemplate) setCustomTemplate(false);
                        setInput({
                          ...input,
                          bg: colorProfiles[key].bg,
                          fg: colorProfiles[key].fg,
                          hr: colorProfiles[key].hr,
                          page: colorProfiles[key].logo,
                        });
                      }}
                    />
                    <label htmlFor={"other-one-" + key}>
                      <div
                        className="color-fenster"
                        style={{
                          background: `linear-gradient(to right, ${colorProfiles[key].bg} 50%, ${colorProfiles[key].hr} 50%)`,
                        }}
                      ></div>
                    </label>
                  </div>
                ))}
                <div className="color-item">
                  <input
                    type="radio"
                    name="custom"
                    key={"custom"}
                    value={"custom"}
                    id="custom"
                    checked={customTemplate}
                    onChange={(e) => {
                      setCustomTemplate(e.target.checked);
                    }}
                  />
                  <label htmlFor="custom">
                    <div className="color-fenster rainbow"></div>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">Select logo</div>

            <div className="form-group">
              <div className="logo-picker">
                {Object.keys(logos).map((key) => (
                  <div className="logo-item" key={"logo-wrapper-" + key}>
                    <input
                      type="radio"
                      name="page"
                      key={key}
                      value={key}
                      id={key}
                      checked={input.page === key}
                      onChange={inputHandler}
                    />
                    <label htmlFor={key}>
                      <img src={logos[key]} alt="logo" />
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {customTemplate && (
              <div className="custom-picker">
                <div className="form-group picker">
                  <label>Background Color</label>
                  <HexColorPicker
                    color={input.bg}
                    onChange={(color) => setInput({ ...input, bg: color })}
                  />
                </div>
                <div className="form-group picker">
                  <label>Foreground Color</label>
                  <HexColorPicker
                    color={input.fg}
                    onChange={(color) => setInput({ ...input, fg: color })}
                  />
                </div>
              </div>
            )}
            <button onClick={saveAsJpeg}>Download Story Image</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Generator;
