import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./Resize.scss";
import { toJpeg } from "html-to-image";

const sizes = {
  hd: {
    width: 1920,
    height: 1080,
  },
  sd: {
    width: 1280,
    height: 720,
  },
};

const Resizer = () => {
  const [scale, setScale] = useState(1);
  const [inputImg, setInputImg] = useState("https://placehold.co/1920x1080");
  const [imgName, setImgName] = useState();
  const ref = useRef(null);
  const previewRef = useRef(null);

  useEffect(() => {
    calculateScale();
    window.addEventListener("resize", calculateScale);
    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, []);

  const calculateScale = () => {
    const windowWidth = window.outerWidth;
    const windowHeight = window.outerHeight;
    console.log("windowW", windowWidth);

    if (windowWidth < 1000) {
      const scaleX = windowWidth / 1920;
      const scaleY = windowHeight / 1080;
      const finalScale = scaleX > scaleY ? scaleY : scaleX;
      setScale(finalScale);
    } else {
      const theScale = windowHeight / 1080;
      console.log("scale", theScale);
      setScale(theScale > 1 ? 1 : theScale);
    }
  };

  const saveAsJpeg = (format) => {
    if (ref.current === null) {
      return;
    }

    const { width, height } = sizes[format];

    toJpeg(ref.current, {
      canvasHeight: height,
      canvasWidth: width,
      quality: 0.95,
      pixelRatio: 1,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        const fileName = imgName.split(".")[0] + "_" + format + ".jpg";
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="App-resizer">
      <div
        className="previewWrapper"
        ref={previewRef}
        style={{ transform: `scaleX(${scale}) scaleY(${scale})` }}
      >
        <header className="App-header" ref={ref}>
          <div className="imgContainer">
            <img className="img" src={inputImg} alt="bg" />
          </div>
        </header>
      </div>
      <div className="controls">
        <div className="inputs">
          <div className="input upload">
            <FileUploader
              handleChange={(file) => {
                setInputImg(URL.createObjectURL(file));
                setImgName(file.name);
              }}
              type="JPG"
            />
          </div>
          <div className="input download">
            <button
              onClick={() => {
                saveAsJpeg("sd");
              }}
            >
              Download SD
            </button>
            <button
              onClick={() => {
                saveAsJpeg("hd");
              }}
            >
              Download HD
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Resizer;
